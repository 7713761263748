import {useLocation} from 'react-router-dom';
import {useEnvironment} from '@providers/Environment.provider';
import {Operation} from '@services/Authz/Environment.service';
import AgentStatusBanner from '@components/molecules/AgentStatusBanner';
import WizardStatusBanner from '@components/molecules/WizardStatusBanner';
import {useCustomization} from '@core/providers/Customization';
import {useDashboard} from '@core/providers/Dashboard/Dashboard.provider';
import {useWizard} from '@core/providers/Wizard';

function isSetupPage(pathname: string, baseUrl: string) {
  const currentPath = pathname.slice(-1) === '/' ? pathname.slice(0, -1) : pathname;
  return currentPath === `${baseUrl}/setup`;
}

interface IProps {
  hasAgentBanner?: boolean;
}

const StatusBanner = ({hasAgentBanner}: IProps) => {
  const {environment} = useEnvironment();
  const {getIsAllowed} = useCustomization();
  const location = useLocation();
  const {baseUrl} = useDashboard();

  const {steps} = useWizard();
  const isAgentStepComplete = steps.find(({state, id}) => id === 'agent' && state === 'completed');
  const isWizardComplete = steps.filter(({state}) => state === 'completed').length === steps.length;

  if (!environment || isSetupPage(location.pathname, baseUrl) || !getIsAllowed(Operation.Configure)) return null;

  const showAgentBanner = environment.agent.status === 'Red' && hasAgentBanner && isAgentStepComplete;

  return (
    (showAgentBanner && <AgentStatusBanner environment={environment} />) ||
    (!isWizardComplete && <WizardStatusBanner environment={environment} />) ||
    null
  );
};

export default StatusBanner;
