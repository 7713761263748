import {TTraceSchemas} from '@core/types/Common.types';
import Span from './Span.model';
import {generateRandomColor} from '../utils/Common';

export type TRawTrace = TTraceSchemas['Trace'];
export type TSpanMap = Record<string, Span>;
export type TServiceMap = Record<string, string>;
type Trace = {
  flat: TSpanMap;
  spans: Span[];
  traceId: string;
  rootSpan: Span;
  services: TServiceMap;
};

const defaultTrace: TRawTrace = {
  traceId: '',
  flat: {},
  tree: {},
};

const defaultServiceColors = [
  '#31d5d54D',
  '#d5319c4D',
  '#693eda4D',
  '#13c22d4D',
  '#c19b6a4D',
  '#21de964D',
  '#d531314D',
];

const Trace = ({traceId = '', flat: rawFlat = {}, tree = {}} = defaultTrace): Trace => {
  const flat: TSpanMap = {};
  const services: TServiceMap = {};

  const spans = Object.values(rawFlat).map(raw => {
    const span = Span(raw);

    // we pick a service color from the array, if the service is not in the array we generate a random color
    if (!!span.service && !services[span.service])
      services[span.service] = defaultServiceColors[Object.keys(services).length] ?? generateRandomColor();
    span.serviceColor = services[span.service] ?? '';
    flat[span.id || ''] = span;

    return span;
  });

  return {
    traceId,
    rootSpan: Span(tree),
    flat,
    spans,
    services,
  };
};

export default Trace;
