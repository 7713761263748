import {BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {EndpointBuilder} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import UserPreferencesService from '@services/UserPreferences.service';
import {TracetestApiTags} from '@core/constants/Test.constants';
import {dataStoreEndpoints} from './endpoints/DataStore.endpoint';
import {expressionEndpoints} from './endpoints/Expression.endpoint';
import {testEndpoints} from './endpoints/Test.endpoint';
import {testRunEndpoints} from './endpoints/TestRun.endpoint';
import {testSuiteEndpoints} from './endpoints/TestSuite.endpoint';
import {testSuiteRunEndpoints} from './endpoints/TestSuiteRun.endpoint';
import {resourceEndpoints} from './endpoints/Resource.endpoint';
import {variableSetEndpoints} from './endpoints/VariableSet.endpoint';
import {settingsEndpoints} from './endpoints/Setting.endpoint';
import {wizardEndpoints} from './endpoints/Wizard.endpoint';
import {runGroupEndpoints} from './endpoints/RunGroup.endpoint';
import {runEndpoints} from './endpoints/Run.endpoint';
import {pollingEndpoints} from './endpoints/Polling.endpoint';
import {monitorEndpoints} from './endpoints/Monitor.endpoint';
import {monitorRunEndpoints} from './endpoints/MonitorRun.endpoint';

const dashboardUrlRegex = /^\/organizations\/([^\/]+)\/environments\/([^\/]+)(?=\/|$)/;
export type TTestApiEndpointBuilder = EndpointBuilder<
  BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
  TracetestApiTags,
  'tracetest'
>;

export type TBaseQueryFn = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

const customBaseQuery: TBaseQueryFn = async (args, api, extraOptions) => {
  const defaultOrganizationId = UserPreferencesService.get('orgId');
  const defaultEnvironmentId = UserPreferencesService.get('envId');

  const matchDashboardUrl = window.location.pathname.match(dashboardUrlRegex);
  const [, organizationId = defaultOrganizationId, environmentId = defaultEnvironmentId] = matchDashboardUrl ?? [];

  return fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('x-source', 'web');
      headers.set('x-organization-id', organizationId);
      headers.set('x-environment-id', environmentId);
    },
  })(args, api, extraOptions);
};

const TracetestAPI = createApi({
  reducerPath: 'tracetest',
  baseQuery: customBaseQuery,
  tagTypes: Object.values(TracetestApiTags),
  endpoints: builder => ({
    ...dataStoreEndpoints(builder),
    ...expressionEndpoints(builder),
    ...testEndpoints(builder),
    ...testRunEndpoints(builder),
    ...testSuiteEndpoints(builder),
    ...testSuiteRunEndpoints(builder),
    ...resourceEndpoints(builder),
    ...variableSetEndpoints(builder),
    ...settingsEndpoints(builder),
    ...wizardEndpoints(builder),
    ...runGroupEndpoints(builder),
    ...runEndpoints(builder),
    ...pollingEndpoints(builder),
    ...monitorEndpoints(builder),
    ...monitorRunEndpoints(builder),
  }),
});

export default TracetestAPI;
