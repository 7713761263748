import {CloseCircleOutlined} from '@ant-design/icons';
import Environment from '@models/Environment.model';
import {Link} from 'react-router-dom';
import * as S from './WizardStatusBanner.styled';

interface IProps {
  environment: Environment;
}

const WizardStatusBanner = ({environment: {organizationID, id}}: IProps) => (
  <S.Alert
    type="warning"
    message="Finish the initial setup to start testing your application"
    showIcon
    icon={<CloseCircleOutlined />}
    action={
      <>
        Go to the <Link to={`/organizations/${organizationID}/environments/${id}/setup`}>Setup</Link> page to complete
        the initial configuration.
      </>
    }
  />
);

export default WizardStatusBanner;
