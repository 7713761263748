export interface IKeyValue {
  value: string;
  key: string;
}

export const DEFAULT_HEADERS: IKeyValue[] = [{key: 'Content-Type', value: 'application/json'}];

export enum TriggerTypes {
  http = 'http',
  grpc = 'grpc',
  traceid = 'traceid',
  kafka = 'kafka',
  cypress = 'cypress',
  playwright = 'playwright',
  artillery = 'artillery',
  k6 = 'k6',
  playwrightEngine = 'playwrightengine',
  graphql = 'graphql',
}

export const TriggerTypesToName = {
  [TriggerTypes.http]: 'HTTP',
  [TriggerTypes.grpc]: 'gRPC',
  [TriggerTypes.traceid]: 'TraceID',
  [TriggerTypes.kafka]: 'Kafka',
  [TriggerTypes.cypress]: 'Cypress',
  [TriggerTypes.playwright]: 'Playwright',
  [TriggerTypes.artillery]: 'Artillery',
  [TriggerTypes.k6]: 'k6',
  [TriggerTypes.playwrightEngine]: 'Playwright Engine',
  [TriggerTypes.graphql]: 'GraphQL',
};

export enum ImportTypes {
  postman = 'postman',
  curl = 'curl',
  definition = 'definition',
}

export enum SortBy {
  Created = 'created',
  LastRun = 'last_run',
  Name = 'name',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type Option<T extends Object> = {
  label: string;
  value: string;
  params: T;
};

export type SortOption = Option<{sortBy: SortBy; sortDirection: SortDirection}>;

export const lastRunSortOption: SortOption = {
  label: 'Last Run',
  value: 'last_run',
  params: {sortDirection: SortDirection.Desc, sortBy: SortBy.LastRun},
};

export const nameAscSortOption: SortOption = {
  label: 'Name, A to Z',
  value: 'name_asc',
  params: {sortDirection: SortDirection.Asc, sortBy: SortBy.Name},
};

export const nameDescSortOption: SortOption = {
  label: 'Name, Z to A',
  value: 'name_desc',
  params: {sortDirection: SortDirection.Desc, sortBy: SortBy.Name},
};

export const createdSortOption: SortOption = {
  label: 'Created',
  value: 'created',
  params: {sortDirection: SortDirection.Desc, sortBy: SortBy.Created},
};

export const sortOptions = [lastRunSortOption, nameAscSortOption, nameDescSortOption, createdSortOption] as const;

export enum TracetestApiTags {
  VARIABLE_SET = 'variableSet',
  TESTSUITE = 'testSuite',
  TESTSUITE_RUN = 'testSuiteRun',
  TEST = 'test',
  TEST_DEFINITION = 'testDefinition',
  TEST_RUN = 'testRun',
  SPAN = 'span',
  EXPRESSION = 'expression',
  RESOURCE = 'resource',
  RUN_GROUP = 'runGroup',
  RUN = 'run',
  RUN_FILTERS = 'runFilters',
  ORGANIZATION_RUN = 'organizationRun',
  ORGANIZATION_RUN_FILTERS = 'organizationRunFilters',
  DATA_STORE = 'dataStore',
  WIZARD = 'wizard',
  SETTING = 'setting',
  POLLING = 'polling',
  MONITOR = 'monitor',
  MONITOR_RUN = 'monitorRun',
}

export const TracetestApiTagsList = Object.values(TracetestApiTags);
