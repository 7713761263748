import {CloseCircleOutlined} from '@ant-design/icons';
import Environment from '@models/Environment.model';
import {Link} from 'react-router-dom';
import * as S from './AgentStatusBanner.styled';

interface IProps {
  environment: Environment;
}

const AgentStatusBanner = ({environment: {organizationID, id}}: IProps) => (
  <S.Alert
    type="error"
    message="Your agent is offline"
    showIcon
    icon={<CloseCircleOutlined />}
    action={
      <>
        Your agent is currently disconnected. Please go to the{' '}
        <Link to={`/organizations/${organizationID}/environments/${id}/settings?tab=agent`}>Agent Settings</Link> to set
        it up.
      </>
    }
  />
);

export default AgentStatusBanner;
