import {external} from './Generated.types';
import {external as cloud} from './Generated.cloud.types';

export type TCollection<Entity> = {
  elements: Entity[];
  count: number;
};

export type TPaginatedCollection<Entity> = {
  items: Entity[];
  count: number;
};

export type TBaseFilters = {
  take?: number;
  skip?: number;
  query?: string;
};

export enum Role {
  Owners = 'owners',
  Members = 'members',
  Admins = 'admins',
  Billers = 'billers',
  Engineers = 'engineers',
  Runners = 'runners',
}

export enum InviteStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Revoked = 'revoked',
}

export enum InviteType {
  Email = 'email',
  Public = 'public',
}

export const RoleToName = {
  [Role.Owners]: 'Owner',
  [Role.Members]: 'Member',
  [Role.Admins]: 'Admin',
  [Role.Billers]: 'Biller',
  [Role.Engineers]: 'Engineer',
  [Role.Runners]: 'Runner',
  agent: 'Agent',
} as const;

export interface IEnv {
  analyticsEnabled: boolean;
  appVersion: string;
  baseApiUrl: string;
  env: string;
  experimentalFeatures: string[];
  measurementId: string;
  serverID: string;
  serverPathPrefix: string;
  segmentLoaded: boolean;
  isTracetestDev: boolean;
  posthogKey: string;
  isEnterprise: boolean;
  licenseName: string;
}

export interface IMockFactory<T, R> {
  (): {
    raw(data?: Partial<R>): R;
    model(data?: Partial<R>): T;
  };
}

export type Modify<T, R> = Omit<T, keyof R> & R;
export type Model<T, R> = Modify<Required<T>, R>;

export type THttpSchemas = external['http.yaml']['components']['schemas'];
export type TTraceSchemas = external['trace.yaml']['components']['schemas'];
export type TTestSchemas = external['tests.yaml']['components']['schemas'];
export type TTriggerSchemas = external['triggers.yaml']['components']['schemas'];
export type TGrpcSchemas = external['grpc.yaml']['components']['schemas'];
export type TTraceIDSchemas = external['traceid.yaml']['components']['schemas'];
export type TKafkaSchemas = external['kafka.yaml']['components']['schemas'];
export type TPlaywrightEngineSchemas = external['playwrightEngine.yaml']['components']['schemas'];
export type TGraphqlSchemas = external['graphql.yaml']['components']['schemas'];
export type TVariableSetSchemas = external['variableSets.yaml']['components']['schemas'];
export type TExpressionsSchemas = external['expressions.yaml']['components']['schemas'];
export type TTestSuiteSchemas = external['testsuites.yaml']['components']['schemas'];
export type TResourceSchemas = external['resources.yaml']['components']['schemas'];
export type TDataStoreSchemas = external['dataStores.yaml']['components']['schemas'];
export type TConfigSchemas = external['config.yaml']['components']['schemas'];
export type TVariablesSchemas = external['variables.yaml']['components']['schemas'];
export type TTestEventsSchemas = external['testEvents.yaml']['components']['schemas'];
export type TLintersSchemas = external['linters.yaml']['components']['schemas'];
export type TTestRunnerSchemas = external['testRunner.yaml']['components']['schemas'];
export type TWizardSchemas = external['wizards.yaml']['components']['schemas'];
export type TRunGroupSchemas = external['runGroups.yaml']['components']['schemas'];
export type TRunSchemas = external['runs.yaml']['components']['schemas'];
export type TMonitorSchemas = external['monitors.yaml']['components']['schemas'];
export type TSelector = TTestSchemas['Selector'];

// cloud
export type TInvitesSchemas = cloud['invites.yaml']['components']['schemas'];
export type TMemberSchemas = cloud['members.yaml']['components']['schemas'];
export type TOrganizationSchemas = cloud['organizations.yaml']['components']['schemas'];
export type TUserSchemas = cloud['users.yaml']['components']['schemas'];
export type TEnvironmentSchemas = cloud['environments.yaml']['components']['schemas'];
export type TTokenSchemas = cloud['tokens.yaml']['components']['schemas'];
export type TAgentSchemas = cloud['agents.yaml']['components']['schemas'];
export type TPlanSchemas = cloud['plans.yaml']['components']['schemas'];
export type TCommonSchemas = cloud['common.yaml']['components']['schemas'];
export type TJoinRequestSchemas = cloud['joinrequests.yaml']['components']['schemas'];
