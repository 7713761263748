import {Model, TMonitorSchemas} from '@core/types/Common.types';
import RunMetadata from './RunMetadata.model';
import TestRun from './TestRun.model';
import TestSuiteRun from './TestSuiteRun.model';
import VariableSet from './VariableSet.model';

// AlertResult

export type TRawAlertResult = TMonitorSchemas['AlertResult'];
export type AlertResult = Model<TRawAlertResult, {webhook: WebhookResult}>;

const defaultAlertResult: TRawAlertResult = {
  alertId: '',
  id: '',
  webhook: {},
  type: 'webhook',
};

export const AlertResult = ({
  alertId = '',
  id = '',
  webhook = {},
  type = 'webhook',
} = defaultAlertResult): AlertResult => ({
  alertId,
  id,
  type,
  webhook: WebhookResult(webhook),
});

// WebhookResult

export type TRawWebhookResult = TMonitorSchemas['WebhookResult'];
type WebhookResult = Model<TRawWebhookResult, {}>;

const defaultWebhookResult: TRawWebhookResult = {
  request: {
    url: '',
    headers: [],
    body: '',
    method: 'GET',
  },
  response: {
    statusCode: 0,
    status: '',
    body: '',
    headers: [],
    error: '',
  },
};

const WebhookResult = ({
  request: {url = '', headers: requestHeaders = [], body: requestBody = '', method = 'GET'} = {},
  response: {statusCode = 0, status = '', body: responseBody = '', headers: responseHeaders = [], error = ''} = {},
} = defaultWebhookResult): WebhookResult => ({
  request: {
    url,
    headers: requestHeaders.map(({key = '', value = ''}) => ({
      key,
      value,
    })),
    body: requestBody,
    method,
  },
  response: {
    statusCode,
    status,
    body: responseBody,
    headers: responseHeaders.map(({key = '', value = ''}) => ({
      key,
      value,
    })),
    error,
  },
});

// MonitorRun

export type TRawMonitorRun = TMonitorSchemas['MonitorRun'];
type MonitorRun = Model<
  TRawMonitorRun,
  {
    monitorName?: string;
    metadata: RunMetadata;
    testRuns: TestRun[];
    testSuiteRuns: TestSuiteRun[];
    variableSet?: VariableSet;
    alerts: AlertResult[];
  }
>;

const defaultMonitorRun: TRawMonitorRun = {
  id: 0,
  monitorId: '',
  monitorVersion: 1,
  runGroupId: '',
  createdAt: '',
  completedAt: '',
  executionType: 'MANUAL',
  lastError: '',
  state: 'CREATED',
  variableSet: {},
  metadata: {},
  testRunsCount: 0,
  testSuiteRunsCount: 0,
  testRuns: [],
  testSuiteRuns: [],
  alerts: [],
};

const MonitorRun = ({
  id = 0,
  monitorId = '',
  monitorVersion = 1,
  runGroupId = '',
  createdAt = '',
  completedAt = '',
  executionType = 'MANUAL',
  lastError = '',
  state = 'CREATED',
  variableSet = {},
  metadata = {},
  testRunsCount = 0,
  testSuiteRunsCount = 0,
  testRuns = [],
  testSuiteRuns = [],
  alerts = [],
} = defaultMonitorRun): MonitorRun => ({
  id,
  monitorId,
  monitorVersion,
  runGroupId,
  createdAt,
  completedAt,
  executionType,
  lastError,
  state,
  variableSet: VariableSet.fromRun(variableSet),
  metadata: RunMetadata(metadata, createdAt),
  testRunsCount,
  testSuiteRunsCount,
  testRuns: testRuns?.map(testRun => TestRun(testRun)) ?? [],
  testSuiteRuns: testSuiteRuns?.map(testSuiteRun => TestSuiteRun(testSuiteRun)) ?? [],
  alerts: alerts?.map(alert => AlertResult(alert)) ?? [],
});

MonitorRun.fromMetadata = (metadata: RunMetadata): MonitorRun | undefined => {
  const {'monitor.id': monitorId = '0', 'monitor.run.id': runId = '', 'monitor.name': monitorName = ''} = metadata;
  if (!runId) {
    return undefined;
  }

  const run = MonitorRun({
    id: Number(runId),
    monitorId,
  });

  return {
    ...run,
    monitorName,
  };
};

export default MonitorRun;
