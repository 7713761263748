export enum MENU_KEYS {
  setup = 'setup',
  runs = 'runs',
  tests = 'tests',
  testsuites = 'testsuites',
  monitors = 'monitors',
  variablesets = 'variablesets',
  settings = 'settings',
}

export const MENU_PATHS = {
  [MENU_KEYS.setup]: ['/setup'],
  [MENU_KEYS.runs]: ['/', '/runs', '/run', '/rungroups'],
  [MENU_KEYS.tests]: ['/tests', '/test'],
  [MENU_KEYS.testsuites]: ['/testsuites', '/testsuite'],
  [MENU_KEYS.monitors]: ['/monitors', '/monitor'],
  [MENU_KEYS.variablesets]: ['/variablesets'],
  [MENU_KEYS.settings]: ['/settings'],
} as const;

export const SIDER_WIDTH = 250;
export const SIDER_COLLAPSED_WIDTH = 80;
