import {Col} from 'antd';
import UrlCodeBlock from '@core/components/CodeBlock/UrlCodeBlock';
import DocsBanner from '@core/components/DocsBanner/DocsBanner';
import {INGESTOR_ENDPOINT_URL} from '@core/constants/Common.constants';
import * as S from './IngestorEndpoints.styled';

interface IProps {
  endpoint: string;
  isServerless: boolean;
}

const IngestorEndpoints = ({endpoint, isServerless}: IProps) => (
  <S.Container>
    <S.Title>Ingestor Endpoint</S.Title>
    <S.Description>
      The Tracetest Agent exposes trace ingestion endpoints on ports 4317 for gRPC and 4318 for HTTP.
    </S.Description>

    <Col span={16}>
      <S.UrlEntry>
        gRPC{' '}
        <UrlCodeBlock
          value={isServerless ? `${endpoint}:443` : `${endpoint}:4317`}
          minHeight="35px"
          maxHeight="35px"
          language="bash"
        />
      </S.UrlEntry>
    </Col>
    <Col span={16}>
      <S.UrlEntry>
        HTTP{' '}
        <UrlCodeBlock
          value={`${isServerless ? `https://${endpoint}:443` : `http://${endpoint}:4318`}`}
          minHeight="35px"
          maxHeight="35px"
          language="bash"
        />
      </S.UrlEntry>
    </Col>
    <Col span={16}>
      <DocsBanner>
        Need more information about setting up the agent ingestion endpoint?{' '}
        <a target="_blank" href={INGESTOR_ENDPOINT_URL}>
          Go to our docs
        </a>
      </DocsBanner>
    </Col>
  </S.Container>
);

export default IngestorEndpoints;
