import {HTTP_METHOD} from '@core/constants/Common.constants';
import {TracetestSources} from '@core/constants/Metadata.constants';
import {TracetestApiTags} from '@core/constants/Test.constants';
import {PaginationResponse} from '@core/hooks/usePagination';
import MonitorRun, {TRawMonitorRun} from '@core/models/MonitorRun.model';
import RunError from '@core/models/RunError.model';
import {TVariableSetValue} from '@core/models/VariableSet.model';
import {TBaseFilters, TPaginatedCollection} from '@core/types/Common.types';
import {TTestApiEndpointBuilder} from '../Tracetest.api';

export const monitorRunEndpoints = (builder: TTestApiEndpointBuilder) => ({
  getMonitorRuns: builder.query<PaginationResponse<MonitorRun>, TBaseFilters & {monitorId: string}>({
    query: ({monitorId, take = 25, skip = 0}) => ({
      url: `/monitors/${monitorId}/run?${new URLSearchParams({
        take: take.toString(),
        skip: skip.toString(),
      })}`,
    }),
    providesTags: (result, error, {monitorId}) => [{type: TracetestApiTags.MONITOR_RUN, id: `${monitorId}-LIST`}],
    transformResponse: ({items = [], count = 0}: TPaginatedCollection<TRawMonitorRun>) => ({
      items: items.map(rawMonitorRun => MonitorRun(rawMonitorRun)) ?? [],
      total: count,
    }),
  }),

  getMonitorRun: builder.query<MonitorRun, {monitorId: string; runId: number}>({
    query: ({monitorId, runId}) => `/monitors/${monitorId}/run/${runId}`,
    providesTags: result => [{type: TracetestApiTags.MONITOR_RUN, id: result?.id}],
    transformResponse: (rawMonitorRun: TRawMonitorRun) => MonitorRun(rawMonitorRun),
  }),

  runMonitor: builder.mutation<MonitorRun, {monitorId: string; variables?: TVariableSetValue[]}>({
    query: ({monitorId, variables = []}) => ({
      url: `/monitors/${monitorId}/run`,
      method: HTTP_METHOD.POST,
      body: {variables, metadata: {source: TracetestSources.Web}},
    }),
    invalidatesTags: (result, error, {monitorId}) => [
      {type: TracetestApiTags.MONITOR_RUN, id: `${monitorId}-LIST`},
      {type: TracetestApiTags.MONITOR, id: monitorId},
    ],
    transformResponse: (rawMonitorRun: TRawMonitorRun) => MonitorRun(rawMonitorRun),
    transformErrorResponse: ({data: result}) => RunError(result),
  }),

  deleteMonitorRun: builder.mutation<MonitorRun, {monitorId: string; runId: number}>({
    query: ({monitorId, runId}) => ({
      url: `/monitors/${monitorId}/run/${runId}`,
      method: HTTP_METHOD.DELETE,
    }),
    invalidatesTags: (result, error, {monitorId}) => [{type: TracetestApiTags.MONITOR_RUN, id: `${monitorId}-LIST`}],
  }),
});
