import styled from 'styled-components';
import {Alert as AntdAlert} from 'antd';

export const Alert = styled(AntdAlert)`
  display: grid;
  gap: 8px;
  grid-template-columns: 25px auto 1fr;
  padding: 16px 24px;

  .ant-alert-message,
  a {
    font-weight: 600;
  }
`;
