import {FramedCodeBlock} from '@core/components/CodeBlock';
import useCommand, {getOptions} from '@core/hooks/useCommand';
import {Tabs} from 'antd';
import * as S from './AgentCommands.styled';

interface IProps {
  apiKey: string;
  isLocal: boolean;
  orgId: string;
  envId: string;
  isCertificateValid: boolean;
}

const AgentCommands = ({apiKey, isLocal, orgId, envId, isCertificateValid}: IProps) => {
  const {serverUrl, skipVerify, insecure} = getOptions(isCertificateValid);
  const cliCommand = useCommand(
    isLocal
      ? `tracetest start --organization ${orgId} --environment ${envId}`
      : `tracetest start --api-key ${apiKey}${insecure} --environment ${envId}`
  );

  const methods = [
    {
      id: 'cli',
      label: 'CLI',
      children: (
        <FramedCodeBlock
          title="Agent Start command:"
          language="bash"
          value={cliCommand}
          minHeight="0px"
          maxHeight="96px"
        />
      ),
    },
    {
      id: 'docker',
      label: 'Docker',
      children: (
        <FramedCodeBlock
          title="Agent Start command:"
          language="bash"
          value={`docker run --rm -it -p 4317:4317 -p 4318:4318 -e TRACETEST_API_KEY="${apiKey}" kubeshop/tracetest-agent ${serverUrl}${skipVerify}${insecure} --environment ${envId}`}
          minHeight="0px"
          maxHeight="96px"
        />
      ),
    },
    {
      id: 'kubernetes',
      label: 'Kubernetes / Helm',
      children: (
        <FramedCodeBlock
          title="Agent Start command:"
          language="bash"
          value={`helm repo add tracetestcloud https://kubeshop.github.io/tracetest-cloud-charts --force-update && helm install agent -n <namespace> tracetestcloud/tracetest-agent --set agent.apiKey=${apiKey} --set agent.environmentId=${envId}`}
          minHeight="0px"
          maxHeight="96px"
        />
      ),
    },
  ];

  return (
    <S.TabsContainer>
      <Tabs size="small">
        {methods.map(({id, label, children}) => (
          <Tabs.TabPane key={id} tab={label}>
            {children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </S.TabsContainer>
  );
};

export default AgentCommands;
