import {Space} from 'antd';
import ResourceDefinitionButton from '@components/molecules/ResourceDefinitionButton';
import DataStoreIcon from '@core/components/DataStoreIcon/DataStoreIcon';
import {SupportedDataStores} from '@core/types/DataStore.types';
import {SupportedDataStoresToName} from '@core/constants/DataStore.constants';
import DataStoreComponentFactory from '@core/components/Settings/DataStorePlugin/DataStoreComponentFactory';
import AllowButton, {Operation} from '@core/components/AllowButton';
import {ResourceType} from '@core/types/Resource.type';
import * as S from './DataStoreConfiguration.styled';
import TestConnectionStatus from '../TestConnectionStatus';

interface IProps {
  isTestConnectionSuccess: boolean;
  isSubmitLoading: boolean;
  dataStoreType: SupportedDataStores;
  configuredDataStoreType?: SupportedDataStores;
  onSubmit(): void;
  onTestConnection(): void;
  isWizard?: boolean;
}

const DataStoreConfiguration = ({
  onSubmit,
  onTestConnection,
  isSubmitLoading,
  isTestConnectionSuccess,
  dataStoreType,
  configuredDataStoreType,
  isWizard = false,
}: IProps) => (
  <>
    <S.TopContainer>
      <S.Row>
        <Space>
          <DataStoreIcon withColor dataStoreType={dataStoreType ?? SupportedDataStores.JAEGER} width="22" height="22" />
          <S.Title level={2}>{SupportedDataStoresToName[dataStoreType ?? SupportedDataStores.JAEGER]}</S.Title>
        </Space>
        {!isWizard && configuredDataStoreType === dataStoreType && (
          <ResourceDefinitionButton resourceId="current" resourceType={ResourceType.DataStore} />
        )}
      </S.Row>

      {dataStoreType && <DataStoreComponentFactory dataStoreType={dataStoreType} />}
    </S.TopContainer>
    <S.ButtonsContainer>
      <TestConnectionStatus onTestConnection={onTestConnection} />
      <AllowButton
        operation={Operation.Configure}
        disabled={!isTestConnectionSuccess}
        loading={isSubmitLoading}
        type="primary"
        onClick={onSubmit}
      >
        {isWizard ? 'Continue' : 'Save'}
      </AllowButton>
    </S.ButtonsContainer>
  </>
);

export default DataStoreConfiguration;
