import Panel from '@components/molecules/Panel';
import DocsBanner from '@core/components/DocsBanner';
import {TRACE_INGESTION_DOCUMENTATION_URL} from '@core/constants/Common.constants';
import {useSettingsValues} from '@core/providers/SettingsValues/SettingsValues.provider';
import {Typography} from 'antd';
import Content from './Content';

const DataStore = () => {
  const {
    dataStoreConfig: {defaultDataStore, mode},
    isLoading,
  } = useSettingsValues();

  return (
    <Panel title="Trace Ingestion">
      <Typography.Paragraph type="secondary">
        <p>Connect your Tracing Backend to Tracetest and unlock the full potential of trace-based testing.</p>
        <DocsBanner>
          Need more information about connecting to Tracing Backends?{' '}
          <a href={TRACE_INGESTION_DOCUMENTATION_URL} target="__blank">
            Learn more in our docs
          </a>
        </DocsBanner>
      </Typography.Paragraph>

      {!isLoading && <Content defaultDataStore={defaultDataStore} mode={mode} />}
    </Panel>
  );
};

export default DataStore;
