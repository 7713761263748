import CollectorConfiguration from '@components/organisms/CollectorConfiguration';
import IngestorEndpoints from '@components/organisms/IngestorEndpoints';
import Environment from '@models/Environment.model';
import EnvironmentAgent from '@models/EnvironmentAgent.model';
import {EnvironmentOperation, useAuthz} from '@hooks/authz';
import {useEnvironment} from '@providers/Environment.provider';
import {useGetEnvironmentAgentQuery} from '@redux/apis/TracetestCloud';
import {skipToken} from '@reduxjs/toolkit/query';
import {Col, Row} from 'antd';

const OpenTelemetryCollector = () => {
  const {checkEnvironment} = useAuthz();
  const canConfigure = checkEnvironment(EnvironmentOperation.Configure);

  const {environment: {id, organizationID = '', agentConfiguration: {serverless}} = Environment()} = useEnvironment();
  const {data: {agent: {serverlessUrl}} = EnvironmentAgent()} = useGetEnvironmentAgentQuery(
    canConfigure
      ? {
          envId: id,
          orgId: organizationID,
        }
      : skipToken,
    {pollingInterval: 1000}
  );

  const ingestorEndpoint = `${serverless ? serverlessUrl || '<not_available>' : '<your_agent_url>'}`;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <IngestorEndpoints endpoint={ingestorEndpoint} isServerless={serverless} />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <CollectorConfiguration endpoint={ingestorEndpoint} isServerless={serverless} />
        </Col>
      </Row>
    </>
  );
};

export default OpenTelemetryCollector;
